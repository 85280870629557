import styled from "@emotion/styled"
import Theme from "../lib/Theme"

export const GridForm = styled.form`
  display: grid;
  gap: 1rem 0.5rem;

  grid-template-columns: repeat(2, 1fr);

  > * {
    grid-column: 1 / -1;
  }

  [data-column="large"] {
    grid-column: span 2;
  }

  [data-column="medium"] {
    grid-column: span 2;
  }

  [data-column="small"] {
    grid-column: span 1;
  }

  ${Theme.Mediaquery.medium.min} {
    gap: 1rem 0.75rem;

    grid-template-columns: repeat(4, 1fr);

    [data-column="large"] {
      grid-column: span 3;
    }

    [data-column="medium"] {
      grid-column: span 2;
    }

    [data-column="small"] {
      grid-column: span 1;
    }
  }
`
