import styled from "@emotion/styled"
import React, { FC } from "react"
import Theme from "../lib/Theme"

export const FormInput: FC<{
  label: string
  htmlFor: string
  required?: boolean
  help?: string
  diap?: boolean
}> = ({ label, children, htmlFor, diap, required, help, ...props }) => (
  <Wrap {...props}>
    <Label htmlFor={htmlFor} diap={diap}>
      {label}
      {required && <span> *</span>}
    </Label>
    {children}
    {help && <Help>{help}</Help>}
  </Wrap>
)

const Label = styled.label<{ diap: boolean }>`
  display: block;
  font-size: 1rem;
  margin-bottom: 0.25rem;
  color: ${(p) => (p.diap ? "#fff" : Theme.Colors.purple.s75)};

  span {
    color: ${Theme.Colors.pink.s100};
  }
`

const Wrap = styled.div`
  position: relative;

  input:not([type="checkbox"]),
  textarea {
    padding: 0.75rem 1rem;
    font-size: 1rem;
    width: 100%;

    border: 1px solid ${Theme.Colors.grey.s10};
    border-radius: 3px;

    color: ${Theme.Colors.purple.s150};

    &:focus {
      color: ${Theme.Colors.purple.s150};
      outline: none;
      border-color: ${Theme.Colors.purple.s100};
    }
  }

  textarea {
    min-height: 8rem;
  }
`

const Help = styled.p`
  font-size: 0.8rem;
  margin: 0.25rem 0 0 0;
`
